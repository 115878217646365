import * as O from 'fp-ts/Option'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateClientPayload, EditFieldParams, Farm } from '../../pages/Clients/commons/types'

type ClientState = {
  onDraft: O.Option<CreateClientPayload>
  farm: Maybe<Farm>
  field: Maybe<EditFieldParams>
}

type ClientSlicer = {
  readonly draft: (state: ClientState, action: PayloadAction<CreateClientPayload>) => void
  readonly undraft: (state: ClientState) => void
  readonly clearFarmState: (state: ClientState) => void
  readonly storeFarmOnDraft: (state: ClientState, action: PayloadAction<Farm>) => void
  readonly storeFieldOnDraft: (state: ClientState, action: PayloadAction<EditFieldParams>) => void
}

const initialState = {
  onDraft: O.none,
  farm: null,
  field: null,
}

export const clientsSlice = createSlice<ClientState, ClientSlicer>({
  name: 'clients',
  initialState,
  reducers: {
    draft: (state, action) => {
      state.onDraft = O.some(action.payload)
    },
    undraft: state => {
      state.onDraft = O.none
    },
    storeFarmOnDraft: (state, action) => {
      state.farm = action.payload
    },
    storeFieldOnDraft: (state, action) => {
      state.field = action.payload
    },
    clearFarmState: state => {
      state.farm = null
    },
  },
})

export const { draft, undraft, clearFarmState, storeFarmOnDraft, storeFieldOnDraft } = clientsSlice.actions
