import * as Types from '../types.d';

import { gql } from '@apollo/client';
import { MeFragmentDoc } from './Me.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, status: Types.User_Status, onboarded: boolean, email: string, group?: { __typename?: 'Group', id: string, name: string } | null, preferences?: { __typename?: 'UserPreferences', areaUnit?: string | null, countryId?: string | null, datePattern?: string | null, lengthUnit?: string | null, massUnit?: string | null, language?: string | null, phone?: string | null, profile?: string | null, regionId?: string | null, showContactDetails?: boolean | null, subregion?: string | null, userId?: string | null, volumeUnit?: string | null, country?: { __typename?: 'Country', id: string, name: string, countryCode: string } | null, region?: { __typename?: 'Region', id: string, name: string } | null } | null, role: { __typename?: 'Role', id: string, name: string, rolesAndPermissions: Array<{ __typename?: 'RoleAndPermission', permission: { __typename?: 'Permission', action: Types.Action_Names, domain?: Types.Domains | null, service: Types.Services } }> } } | null };


export const GetMeDocument = gql`
    query GetMe {
  me {
    ...MeFragment
  }
}
    ${MeFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;