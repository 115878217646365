import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form-new'
import {
  Box,
  Button,
  CircularProgress,
  colors,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Select from '../../components/Select/Select'
import SelectCountry from '../../components/SelectCountry'
import SelectRegion from '../../components/SelectRegion'
import PageHeading from '../../components/PageHeading'
import SwitchSelect from '../../components/SwitchSelect'
import { UpdateUserPreferencesDTO } from './types/preferences'
import { datePatterns } from './constants'
import { useQualityAssuranceNamespace } from '../../utils/qa/dataTest'
import { CountriesContext } from '../../contexts/countries/CountriesContext'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
  },
  buttonGroup: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  unitButton: {
    fontWeight: 400,
  },
  inactiveButton: {
    background: theme.palette.common.white,
    '&:hover': {
      background: colors.blue[50],
    },
  },
  wrapper: {
    background: theme.palette.common.white,
    width: 400,
  },
}))

type SettingsFormProps = {
  readonly loading: boolean
  readonly initial: UpdateUserPreferencesDTO
  readonly onSave: SubmitHandler<UpdateUserPreferencesDTO>
  readonly onFail: SubmitErrorHandler<UpdateUserPreferencesDTO>
}

export const SettingsForm: React.FC<SettingsFormProps> = ({ initial, loading, onSave, onFail }) => {
  const classes = useStyles()
  const qaId = useQualityAssuranceNamespace('settings')
  const countries = React.useContext(CountriesContext)

  const { t } = useTranslation()
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm<UpdateUserPreferencesDTO>({
    defaultValues: initial,
  })

  return (
    <Box p={4} className={classes.wrapper}>
      <PageHeading label={'pages.settings.title'} />

      <Box mt={5} mb={4}>
        <Typography className={classes.sectionTitle}>{t('pages.settings.localization')}</Typography>
      </Box>

      <form onSubmit={handleSubmit(onSave, onFail)}>
        <Box mb={2}>
          <Controller
            name="language"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                id="language"
                name="language"
                label={t('pages.settings.default-language')}
                onChange={onChange}
                value={value}
              >
                <MenuItem value="en">{t('languages.english')}</MenuItem>
                <MenuItem value="pt-BR">{t('languages.portugese')}</MenuItem>
                <MenuItem value="es-AR">{t('languages.spanish')}</MenuItem>
                <MenuItem value="uk">{t('languages.ukrainian')}</MenuItem>
              </Select>
            )}
          />
        </Box>
        <Box mb={2} mt={4}>
          <Controller
            name="defaultCountryId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectCountry
                required={true}
                label={t('pages.settings.default-country')}
                onChange={onChange}
                value={value}
                countries={countries}
              />
            )}
          />
        </Box>
        <Box mb={2} mt={4}>
          <Controller
            name="defaultRegionId"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectRegion
                required={true}
                disabled={!watch('defaultCountryId')}
                countryId={watch('defaultCountryId')}
                label={t('pages.settings.default-region')}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Box>
        <Box mt={4}>
          <Controller
            name="datePattern"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                id="datePattern"
                required
                label={t('pages.settings.date-preference')}
                name="datePattern"
                onChange={onChange}
                value={value}
              >
                {_.map(datePatterns, pattern => (
                  <MenuItem key={pattern} value={pattern}>
                    {pattern}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box mt={5}>
          <Box mb={4}>
            <Typography className={classes.sectionTitle}>{t('pages.settings.unit-preferences')}</Typography>
          </Box>

          <Box my={1}>
            <Controller
              name="areaUnit"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SwitchSelect
                  value={value}
                  onChange={onChange}
                  options={[
                    { value: 'ha', title: t('units.hectares'), qa: qaId('unit-system-metric') },
                    { value: 'ac', title: t('units.acres'), qa: qaId('unit-system-imperial') },
                  ]}
                />
              )}
            />
          </Box>

          <Box my={1}>
            <Controller
              name="massUnit"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SwitchSelect
                  value={value}
                  onChange={onChange}
                  options={[
                    { value: 'kg', title: t('units.kg'), qa: qaId('unit-system-metric') },
                    { value: 'lb', title: t('units.lb'), qa: qaId('unit-system-imperial') },
                  ]}
                />
              )}
            />
          </Box>

          <Box my={1}>
            <Controller
              name="volumeUnit"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <SwitchSelect
                  value={value}
                  onChange={onChange}
                  options={[
                    { value: 'l', title: t('units.liters'), qa: qaId('unit-system-metric') },
                    { value: 'gal', title: t('units.gallons'), qa: qaId('unit-system-imperial') },
                  ]}
                />
              )}
            />
          </Box>

          <Controller
            name="lengthUnit"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SwitchSelect
                value={value}
                onChange={onChange}
                options={[
                  { value: 'meter', title: t('units.meters'), qa: qaId('unit-system-metric') },
                  { value: 'inch', title: t('units.inches'), qa: qaId('unit-system-imperial') },
                ]}
              />
            )}
          />
        </Box>

        <Box mt={4}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: t('edit-preferences-info', { editProfileLink: 'myaccount.yara.com' }) }}
          />
        </Box>
        <Box mb={6} mt={2}>
          <FormGroup>
            <Controller
              name="showContactDetails"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label={t('pages.settings.show-contact-details')}
                  control={<Switch name="showContactDetails" color="primary" checked={value} onChange={onChange} />}
                />
              )}
            />
          </FormGroup>
        </Box>

        <Box mt={4}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth={true}
            disabled={loading || !isDirty}
            startIcon={loading ? <CircularProgress size={24} color="secondary" /> : null}
          >
            {t('actions.save')}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
