import { LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-react'

import { impersonate } from '../../userprofile/impersonation'
import { analytics } from '../../utils/analytics'
import { Monitoring } from '../../utils/monitoring'

type Login = (options?: RedirectLoginOptions | undefined) => Promise<void>
type Logout = (options?: LogoutOptions | undefined) => void

export const onNotLogged = (login: Login) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('SSO - Redirected', { source_url: window.location.href, error: error?.message })
    await login()
  }
}

export const onUnexpected = (logout: Logout) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('User - Sign in fail', { source_url: window.location.href, error: error?.message })

    try {
      logout({ logoutParams: { returnTo: window.ENV.REACT_APP_URL } })
    } catch (e) {
      Monitoring.error(new Error(String(e)), {
        kind: 'logout',
      })
    }
  }
}

export const onTokenExpired = (logout: Logout) => {
  return async (error: Error) => {
    Monitoring.error(error, { source_url: window.location.href })
    await analytics.track('User - Signed Out', { source_url: window.location.href, error: error?.message })

    try {
      const language = localStorage.getItem('language') || ''
      impersonate.clear()
      logout({ logoutParams: { returnTo: window.ENV.REACT_APP_URL } })
      localStorage.setItem('language', language)
    } catch (e) {
      Monitoring.error(new Error(String(e)), {
        kind: 'logout',
      })
    }
  }
}
