import React from 'react'
import { IconButton, IconButtonProps } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { styled } from '@material-ui/core/styles'

const Button = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1.5),
}))

const CloseButton: React.FC<IconButtonProps> = props => (
  <Button aria-label="close" color="primary" {...props}>
    <CloseIcon />
  </Button>
)

export default CloseButton
