/* -------------------------------------------------------------------------------------------------
 * Profile Constants
 * -----------------------------------------------------------------------------------------------*/

export const EMPTY = ''

export const DEFAULTS = {
  AREA_UNIT: 'ha',
  DATE_PATTERN: 'dd/MM/yyyy',
  LANGUAGE: 'en',
  LENGTH_UNIT: 'meter',
  MASS_UNIT: 'kg',
  VOLUME_UNIT: 'l',
  COUNTRY_ID: EMPTY,
  REGION_ID: EMPTY,
}
