import { createTheme, ThemeOptions } from '@material-ui/core/styles'
import { set } from 'lodash'
import { colors, typography } from './theme-v2-definitions'
import { Theme } from '@material-ui/core'

const columnFlexbox: {
  display: string
  flexDirection:
    | 'column'
    | 'row'
    | '-moz-initial'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'unset'
    | 'column-reverse'
    | 'row-reverse'
    | undefined
} = {
  display: 'flex',
  flexDirection: 'column',
}

const defaultTheme = createTheme({})

const trickTypes = {}
// Material seems to miss some component props in its types
set(trickTypes, 'component', 'div')

type ThemeOptionsExtended = ThemeOptions & {
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        color: string
      }
    }
  }
}

export const createLocalTheme = ({
  primaryColor = colors.text.primary,
  secondaryColor = '#1370BC',
  textPrimaryColor = colors.text.primary,
  textSecondaryColor = '#003E7E',
  errorColor = '#FF0202',
  borderColor = '#E9E9EB',
  disableColor = '#EFECEC',
}): ThemeOptionsExtended => ({
  palette: {
    primary: {
      dark: '#003E7E',
      light: secondaryColor,
      main: primaryColor,
    },
    secondary: {
      dark: '#003E7E',
      main: secondaryColor,
      light: '#f3f6f9',
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
      hint: '#6E7280',
    },
    error: {
      main: errorColor,
    },
    divider: borderColor,
    action: {
      hover: '#f3f6f9',
      selected: '#f3f6f9',
    },
    grey: {
      100: '#F9F9F9',
    },
    background: {
      default: '#fff',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false,
    },
    MuiButton: {
      size: 'large',
      color: 'secondary',
    },
    MuiDialogTitle: {
      disableTypography: false,
    },
    MuiInputLabel: {
      color: 'secondary',
      disableAnimation: true,
      shrink: true,
    },
    MuiExpansionPanel: {
      TransitionProps: {
        unmountOnExit: true,
      },
    },
    MuiFormLabel: {
      focused: false,
    },
    MuiMenuList: trickTypes,
    MuiTable: trickTypes,
    MuiTableHead: trickTypes,
    MuiTableBody: trickTypes,
    MuiTableRow: trickTypes,
    MuiTablePagination: trickTypes,
    MuiTableCell: {
      component: 'div',
    },
  },
  typography,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: '#FFF',
          fontWeight: undefined,
        },
      },
    },
    MuiIconButton: {
      edgeEnd: {
        color: colors.text.tertiary,
      },
    },
    MuiButton: {
      contained: {
        boxShadow: undefined,
        '&:hover': {
          boxShadow: undefined,
        },
        '&:active': {
          boxShadow: undefined,
        },
        '&:focus': {
          boxShadow: undefined,
        },
      },
      containedPrimary: {
        '&.Mui-disabled': {
          color: '#FFF',
        },
        color: colors.text.white,
        backgroundColor: colors.blue.default,
      },
      containedSecondary: {
        '&.Mui-disabled': {
          color: '#FFF',
        },
      },
      outlinedPrimary: {
        color: colors.blue.default,
        borderColor: colors.text.secondary,
      },
      root: {
        borderRadius: 2 /* Adjust borderRadius to designs, default is 4px */,
        textTransform: 'none' /* We do not need capitalization */,
        fontWeight: 500,
        fontSize: '1rem',
      },
    },
    MuiCheckbox: {
      root: {
        color: '',
        padding: 0,
      },
    },
    MuiChip: {
      deleteIcon: {
        color: 'inherit',
      },
    },
    MuiDialogTitle: {
      root: {
        fontSize: 18,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        maxHeight: 360,
        minHeight: 300,
        padding: 0,
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        alignItems: 'center',
      },
      root: {
        marginBottom: 16,
        marginTop: 16,
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex' /* Input fields should fill all available space */,
      },
    },
    MuiInput: {
      inputMarginDense: {
        [defaultTheme.breakpoints.down('md')]: {
          padding: defaultTheme.spacing(1, 2),
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: (defaultTheme.palette?.text as any)?.tertiary,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottomColor: secondaryColor,
        },
        '&.Mui-focused': {
          '&:after': {
            borderBottomColor: secondaryColor,
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        backgroundColor: '#F9F9F9',
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        padding: '11px 12px 12px',
        outline: 'none !important',
        '&::placeholder': {
          color: '#979797',
          fontWeight: 'normal',
          opacity: 1,
        },
        '&:focus': {
          outlineColor: primaryColor,
          outlineStyle: 'auto',
          outlineWidth: 1,
        },
      },
      inputMultiline: {
        padding: '4px 8px',
      },
      root: {
        color: colors.text.primary,
        '&.Mui-error': {
          color: errorColor,
        },
      },
    },
    MuiInputLabel: {
      /* Turn off inputLabel animation and transformation */
      formControl: {
        position: 'relative',
        transform: 'none',
      },
      root: {
        fontSize: '1.25rem',
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiLink: {
      root: {
        textTransform: 'uppercase',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          color: primaryColor,
        },
      },
      root: {
        '&.Mui-selected': {
          color: '#52575C',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit' /* Inheriting the color is better than just being black by default */,
        minWidth: 32,
      },
    },
    MuiListItemText: {
      root: {
        maxWidth: '100%', // IE11
      },
    },
    MuiMenu: {
      paper: {
        border: '1px solid #EDEAEA',
        boxShadow: '0px 0px 8px rgba(37, 40, 43, 0.12)',
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
        '&::-webkit-scrollbar': {
          background: 'transparent',
          width: defaultTheme.spacing(1),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#7F7F7F',
          borderRadius: defaultTheme.spacing(0.5),
        },
      },
    },
    MuiMenuItem: {
      root: {
        paddingBottom: '0.75em',
        paddingTop: '0.75em',
        color: colors.text.secondary,

        '&:hover': {
          backgroundColor: '#F3F6F9',
          color: colors.text.secondary,
        },
      },
    },
    MuiSelect: {
      icon: {
        bottom: 9, // for IE11
        top: 'auto',
      },
      root: {},
      select: {
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
      },
      selectMenu: {
        alignItems: 'center',
        display: 'flex',
      },
    },
    MuiTab: {
      root: {
        display: 'flex',
        minHeight: 36,
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'none',
        fontWeight: 600,
        fontSize: 16,
        '&.Mui-selected': {
          zIndex: 25,
          color: secondaryColor,
          borderBottomWidth: 2,
          borderBottomStyle: 'solid',
          borderBottomColor: secondaryColor,
        },
        '&.Mui-selected .MuiSvgIcon-root': {
          fill: secondaryColor,
        },
      },
      labelIcon: {
        marginRight: 10,
      },
      wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > div': {
          width: 30,
          height: 18,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 36,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: disableColor,
      },
      indicator: {
        zIndex: 2,
        backgroundColor: '#fff',
      },
    },
    MuiTable: {
      root: {
        border: '1px solid #D5D5D5',
        boxSizing: 'border-box',
        ...columnFlexbox,
        [defaultTheme.breakpoints.down('md')]: {
          padding: 8,
        },
      },
    },
    MuiTableBody: {
      root: {
        ...columnFlexbox,
        maxHeight: 400,
        overflowY: 'auto',
      },
    },
    MuiTableCell: {
      body: {
        color: '#636363',
        fontWeight: 400,
      },
      head: {
        color: colors.text.tertiary,
        flexWrap: 'wrap',
        fontWeight: 500,
        paddingBottom: 16,
        paddingTop: 10,
      },
      footer: {
        color: '#979797',
        fontWeight: 'bold',
      },
      root: {
        alignItems: 'flex-start',
        borderBottom: 'none',
        display: 'flex',
        flexBasis: 200,
        flexGrow: 1,
        flexShrink: 0,
        [defaultTheme.breakpoints.down('md')]: {
          padding: 4,
        },
      },
    },
    MuiTableFooter: {
      root: {
        ...columnFlexbox,
        backgroundColor: '#edeaea',
      },
    },
    MuiTableHead: {
      root: {
        ...columnFlexbox,
      },
    },
    MuiTableRow: {
      footer: {
        flexDirection: 'row-reverse',
      },
      root: {
        borderBottom: `1px solid ${borderColor}`,
        display: 'flex',
        flexShrink: 0, // IE11

        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 62, 126, 0.1)',
          '&:hover': {
            backgroundColor: 'rgba(0, 62, 126, 0.1)',
          },
        },
      },
    },
    MuiTooltip: {
      arrow: {
        color: primaryColor,
      },
      tooltip: {
        backgroundColor: primaryColor,
        fontSize: '0.8rem',
      },
    },
    MuiDialog: {
      root: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: 0,
        },
      },
      paper: {
        borderRadius: defaultTheme.spacing(1),
        [defaultTheme.breakpoints.down('sm')]: {
          margin: defaultTheme.spacing(1),
        },
      },
      paperFullWidth: {
        [defaultTheme.breakpoints.down('sm')]: {
          padding: defaultTheme.spacing(4, 1),
          width: `calc(100% - ${defaultTheme.spacing(2)})`,
        },
        [defaultTheme.breakpoints.up('md')]: {
          padding: defaultTheme.spacing(3),
        },
        [defaultTheme.breakpoints.down('md')]: {
          padding: defaultTheme.spacing(2, 1),
        },
      },
    },
    MuiTablePagination: {
      caption: {
        fontSize: '14px',
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: 'lightgrey',
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: secondaryColor,
      },
      colorSecondary: {
        color: '#fff',
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: colors.text.tertiary,
      },
    },
  },
})

const theme = createTheme(createLocalTheme({})) as Theme

export default theme
