import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetProgramRecommendation_programRecommendation } from '../../pages/Recommendations/hooks/types/GetProgramRecommendation'

type ProgramsState = {
  showProgram: GetProgramRecommendation_programRecommendation
}

type ProgramsSlicer = {
  readonly setShowProgram: (
    state: ProgramsState,
    action: PayloadAction<GetProgramRecommendation_programRecommendation>
  ) => void
}

export const programsSlice = createSlice<ProgramsState, ProgramsSlicer>({
  name: 'programs',
  initialState: {
    showProgram: {} as GetProgramRecommendation_programRecommendation,
  },
  reducers: {
    setShowProgram: (state, action) => {
      state.showProgram = action.payload
    },
  },
})

export const { setShowProgram } = programsSlice.actions
