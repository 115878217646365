import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const Helm = props => {
  const { t } = useTranslation()
  const baseTitle = t('head.title')

  return (
    <Helmet defaultTitle={baseTitle} titleTemplate={`%s - ${baseTitle}`}>
      {props.title && <title>{props.title}</title>}
      <meta name="description" content={t('head.description')} />
    </Helmet>
  )
}

export default Helm
