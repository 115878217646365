export type Operand = string | number | { reference: string }
type Operator = '+' | '-' | '*' | '/'

function resolveReference(operand: Operand): string {
  if (typeof operand === 'object' && operand.reference) {
    return operand.reference
  }
  return String(operand)
}

const toExpression = (operator: Operator, ...operands: Array<Operand>) =>
  operands.map(resolveReference).join(` ${operator} `).replace(/calc/g, '')

const add = (...operands: Array<Operand>) => `calc(${toExpression('+', ...operands)})`

const subtract = (...operands: Array<Operand>) => `calc(${toExpression('-', ...operands)})`

const multiply = (...operands: Array<Operand>) => `calc(${toExpression('*', ...operands)})`

const clamp = (min: Operand, desired: Operand, max: Operand) => `clamp(${min}, ${desired}, ${max})`

const divide = (...operands: Array<Operand>) => `calc(${toExpression('/', ...operands)})`

const negate = (x: Operand) => {
  const value = resolveReference(x)

  if (value != null && !Number.isNaN(parseFloat(value))) {
    return String(value).startsWith('-') ? String(value).slice(1) : `-${value}`
  }

  return multiply(value, -1)
}

interface CalcChain {
  add: (...operands: Array<Operand>) => CalcChain
  subtract: (...operands: Array<Operand>) => CalcChain
  multiply: (...operands: Array<Operand>) => CalcChain
  divide: (...operands: Array<Operand>) => CalcChain
  negate: () => CalcChain
  clamp: (min, desired, max) => string
  toString: () => string
}

export const calc = Object.assign(
  (x: Operand): CalcChain => ({
    add: (...operands) => calc(add(x, ...operands)),
    subtract: (...operands) => calc(subtract(x, ...operands)),
    multiply: (...operands) => calc(multiply(x, ...operands)),
    divide: (...operands) => calc(divide(x, ...operands)),
    negate: () => calc(negate(x)),
    clamp: (min, desired, max) => clamp(min, desired, max),
    toString: () => x.toString(),
  }),
  {
    add,
    subtract,
    multiply,
    divide,
    clamp,
    negate,
  }
)

export function clampBuilder(minWidthPx, maxWidthPx, minFontSize, maxFontSize) {
  const root = document.querySelector('html') as HTMLElement
  const pixelsPerRem = Number(getComputedStyle(root).fontSize.slice(0, -2))

  const minWidth = minWidthPx / pixelsPerRem
  const maxWidth = maxWidthPx / pixelsPerRem

  const slope = (maxFontSize - minFontSize) / (maxWidth - minWidth)
  const yAxisIntersection = -minWidth * slope + minFontSize

  return `clamp(${minFontSize}rem, ${yAxisIntersection}rem + ${slope * 100}vw, ${maxFontSize}rem)`
}
