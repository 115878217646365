import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWrapper: React.FC = ({ children }) => {
  const history = useHistory()
  const auth0Domain = window.ENV.REACT_APP_AUTH0_AUTHORITY_URL
  const auth0ClientId = window.ENV.REACT_APP_AUTH0_CLIENT_ID

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      leeway={120}
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/programs`,
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={'localstorage'}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWrapper
