import _isString from 'lodash/isString'

export const isDigits = (str: string): boolean => /^\d+$/.test(str)

export const upperFirst = (value: string | number = ''): string => {
  if (!_isString(value)) {
    return ''
  }

  return `${value.substr(0, 1).toUpperCase()}${value.substr(1).toLowerCase()}`
}
export const toKebabCase = (str: string) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)!
    .map(x => x.toLowerCase())
    .join('-')
