import * as Types from '../types.d';

import { gql } from '@apollo/client';
import { UserListFragmentDoc } from './UserList.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type SetUserPreferencesMutationVariables = Types.Exact<{
  userId: Types.Scalars['String'];
  preferences: Types.UserPreferencesInput;
}>;


export type SetUserPreferencesMutation = { __typename?: 'Mutation', setUserPreferences: { __typename?: 'User', id: string, givenName: string, familyName: string, email: string, lastLoggedIn?: string | null, source: Types.User_Source, status: Types.User_Status, group?: { __typename?: 'Group', id: string, name: string } | null, role: { __typename?: 'Role', id: string, name: string, roleLevel: number, rolesAndPermissions: Array<{ __typename?: 'RoleAndPermission', permission: { __typename?: 'Permission', action: Types.Action_Names, service: Types.Services, domain?: Types.Domains | null } }> }, preferences?: { __typename?: 'UserPreferences', language?: string | null, country?: { __typename?: 'Country', name: string } | null, region?: { __typename?: 'Region', name: string } | null } | null } };


export const SetUserPreferencesDocument = gql`
    mutation SetUserPreferences($userId: String!, $preferences: UserPreferencesInput!) {
  setUserPreferences(userId: $userId, preferences: $preferences) {
    ...UserList
  }
}
    ${UserListFragmentDoc}`;
export type SetUserPreferencesMutationFn = Apollo.MutationFunction<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>;

/**
 * __useSetUserPreferencesMutation__
 *
 * To run a mutation, you first call `useSetUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserPreferencesMutation, { data, loading, error }] = useSetUserPreferencesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useSetUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>(SetUserPreferencesDocument, options);
      }
export type SetUserPreferencesMutationHookResult = ReturnType<typeof useSetUserPreferencesMutation>;
export type SetUserPreferencesMutationResult = Apollo.MutationResult<SetUserPreferencesMutation>;
export type SetUserPreferencesMutationOptions = Apollo.BaseMutationOptions<SetUserPreferencesMutation, SetUserPreferencesMutationVariables>;