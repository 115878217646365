import { DatePattern, UpdateUserPreferencesDTO } from './types/preferences'

export const datePatterns: DatePattern[] = ['dd/MM/yyyy', 'MM/dd/yyyy', 'yyyy/MM/dd']

export const profileFallback: UpdateUserPreferencesDTO = {
  areaUnit: 'ha',
  datePattern: 'dd/MM/yyyy',
  language: '',
  defaultCountryId: '',
  defaultRegionId: '',
  lengthUnit: 'meter',
  massUnit: 'kg',
  showContactDetails: false,
  volumeUnit: 'l',
}
