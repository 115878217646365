import React from 'react'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import { flow, pipe, unsafeCoerce } from 'fp-ts/function'
import { CountriesContext } from './CountriesContext'
import { useGetCountriesQuery } from '../../graphql/gateway/queries/GetCountries.generated'
import { LoadingCircular } from '../../components/LoadingCircular'
import LoadingError from '../../components/LoadingError'
import type { Country, GetCountriesQuery } from '../../graphql/gateway/types'

function handleQueryData({ countries }: GetCountriesQuery): ReadonlyArray<Country> {
  return pipe(countries.entities, RA.map(unsafeCoerce<any, Country>))
}

const CountriesContextProvider: React.FC = ({ children }) => {
  const [error, setError] = React.useState<O.Option<Error>>(O.none)
  const [countries, setCountries] = React.useState<O.Option<ReadonlyArray<Country>>>(O.none)

  const { loading } = useGetCountriesQuery({
    fetchPolicy: 'cache-first',
    onError: flow(O.some, setError),
    onCompleted: flow(handleQueryData, O.some, setCountries),
    variables: {
      sorting: { column: 'name', orderDirection: 'ASC' },
      filter: [{ key: 'applicationTags', type: 'IN', value: 'Ayra' }],
    },
  })

  if (loading) {
    return <LoadingCircular />
  }

  if (O.isSome(error)) {
    return <LoadingError errorText={error.value.message} />
  }

  if (O.isNone(countries)) {
    return <LoadingError errorText={'Not found the countries'} />
  }

  return <CountriesContext.Provider value={countries.value}>{children}</CountriesContext.Provider>
}

export default CountriesContextProvider
