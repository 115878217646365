import _ from 'lodash'
import * as RA from 'fp-ts/ReadonlyArray'
import { pipe } from 'fp-ts/function'
import {
  ApolloClient,
  ApolloLink,
  DefaultContext,
  FetchResult,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  Operation,
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { impersonate } from '../userprofile/impersonation'
import { offsetLimitPagination } from '@apollo/client/utilities'
import { Monitoring } from '../utils/monitoring'

function injectAuthorizationToken(token: string) {
  return function (context: DefaultContext): DefaultContext {
    return {
      ...context,
      headers: {
        ...context.headers,
        Authorization: token,
      },
    }
  }
}

export function createApolloLink(token: string): ApolloLink {
  return new ApolloLink(function (operation, forward) {
    operation.setContext(injectAuthorizationToken(token))
    return forward(operation)
  })
}

function createImpersonationApolloLink(): ApolloLink {
  return new ApolloLink(function (operation, forward) {
    const impersonation = impersonate.get()

    if (_.isNil(impersonation)) {
      return forward(operation)
    }

    operation.setContext(function (context: DefaultContext): DefaultContext {
      return {
        ...context,
        headers: {
          ...context.headers,
          Impersonate: impersonation.id,
        },
      }
    })

    return forward(operation)
  })
}

const createApolloLinkSplit = ApolloLink.split(
  (operation: Operation) => operation.getContext().gateway,
  new HttpLink({
    uri: (operation: Operation) => `${window.ENV.REACT_APP_GATEWAY_ENDPOINT}?q=${operation.operationName}`,
  }),
  new HttpLink({
    uri: (operation: Operation) => `${window.ENV.REACT_APP_API_GRAPHQL_ENDPOINT}?q=${operation.operationName}`,
  })
)

function graphqlErrorHandler(error: ErrorResponse): Observable<FetchResult> {
  pipe(
    error.graphQLErrors || [],
    RA.map(e => new Error(e.message, { cause: e })),
    RA.concat(error.networkError ? [error.networkError] : []),
    RA.map(e =>
      Monitoring.error(e, {
        kind: 'graphql',
        operation: error.operation.operationName,
        reason: JSON.stringify(e.cause),
      })
    )
  )

  return error.forward(error.operation)
}

export function createErrorLink() {
  return onError(graphqlErrorHandler)
}

export function getClient(token: string): ApolloClient<NormalizedCacheObject> {
  const apolloLinkOptions = ApolloLink.from([
    createErrorLink(),
    createApolloLink(token),
    createImpersonationApolloLink(),
    createApolloLinkSplit,
  ])

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          standardProducts: offsetLimitPagination(),
        },
      },
    },
  })

  return new ApolloClient({
    cache,
    link: apolloLinkOptions,
    devtools: {
      name: 'logged',
      enabled: window.ENV.REACT_APP_ENV_NAME !== 'production',
    },
    queryDeduplication: true,
    name: 'new-ayra',
    version: window.ENV.REACT_APP_VERSION,
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'ignore',
      },
    },
  })
}
