import React from 'react'
import { Router } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'

import routes from './routes'
import store from './store'
import GlobalStyle from './style/GlobalStyle'
import AuthProvider from './contexts/auth/AuthProvider'
import LaunchDarklyProvider from './contexts/launchDarkly/LaunchDarklyProvider'
import Auth0ProviderWrapper from './contexts/auth/Auth0ProviderWrapper'
import I18nProvider from './contexts/i18nProvider'

import { MainLayoutWrapper } from './components/MainLayoutWrapper'
import { LoadingCircular } from './components/LoadingCircular'
import SettingsProvider from './pages/UserSettings/SettingsProvider'
import CountriesContextProvider from './contexts/countries/CountriesContextProvider'

const PublishProvider = React.lazy(
  () => import(/* webpackChunkName: "PublishProvider" */ './pages/Publish/provider/PublishProvider')
)
const MaintenancePage = React.lazy(
  () => import(/* webpackChunkName: "MaintenancePage" */ './pages/Maintenance/MaintenancePage')
)

const App: React.FC = () => {
  const history = createBrowserHistory()

  return (
    <GlobalStyle>
      <React.Suspense fallback={<LoadingCircular />}>
        <Router history={history}>
          <Switch>
            <Route component={MaintenancePage} path={routes.maintenance} />
            <Route component={PublishProvider} path={routes.publish} />
            <Route>
              <Provider store={store}>
                <I18nProvider>
                  <Auth0ProviderWrapper>
                    <AuthProvider>
                      <LaunchDarklyProvider>
                        <SettingsProvider>
                          <CountriesContextProvider>
                            <MainLayoutWrapper />
                          </CountriesContextProvider>
                        </SettingsProvider>
                      </LaunchDarklyProvider>
                    </AuthProvider>
                  </Auth0ProviderWrapper>
                </I18nProvider>
              </Provider>
            </Route>
          </Switch>
        </Router>
      </React.Suspense>
    </GlobalStyle>
  )
}

export default App
