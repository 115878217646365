import React from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Box, ThemeProvider } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import { UserPreferencesContext } from '../contexts/preferences/UserPreferencesContext'
import DiscardChangesProvider from '../contexts/discard/DiscardChangesProvider'

import TopBar from './TopBar'
import SettingsSidebar from '../pages/UserSettings/SettingsSidebar'
import routes, { signupRoutes } from '../routes'
import theme from '../theme-v2'
import Routing from '../pages/Routing'
import Footer from '../containers/Footer/Footer'
import CustomNavigationPrompt from './CustomNavigationPrompt'
import Snackbar from './Notification/Snackbar'
import { SIDEBAR_NAVIGATION_WIDTH } from './Navigation/constants'
import { LoadingCircular } from './LoadingCircular'

const Header = React.lazy(() => import(/* webpackChunkName: "Header" */ './../containers/Header/Header'))
const Navigation = React.lazy(() => import(/* webpackChunkName: "Navigation" */ './Navigation/Navigation'))
const OnboardingPage = React.lazy(
  () => import(/* webpackChunkName: "OnboardingPage" */ '../pages/Onboarding/OnboardingPage')
)
const PendingAuthorizationPage = React.lazy(
  () =>
    import(/* webpackChunkName: "PendingAuthorizationPage" */ '../pages/PendingAuthorization/PendingAuthorizationPage')
)

/* -------------------------------------------------------------------------------------------------
 * CustomStyles
 * -----------------------------------------------------------------------------------------------*/

const useStyles = makeStyles(() => ({
  root: {
    padding: '3vw',
    position: 'relative',
    maxWidth: '1440px',
    margin: '0 auto',
    width: '100%',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
  },
  sidebarOffset: {
    flex: `${SIDEBAR_NAVIGATION_WIDTH}px 0 0`,
  },
}))

/* -------------------------------------------------------------------------------------------------
 * Main Layout Wrapper
 * -----------------------------------------------------------------------------------------------*/

export const MainLayoutWrapper: React.FC = () => {
  const { i18n } = useTranslation()
  const history = useHistory()

  const classes = useStyles()
  const userPreferences = React.useContext(UserPreferencesContext)
  const currentLanguage = localStorage.getItem('language')

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.pendo.initialize({
      visitor: {
        id: userPreferences.userId, // Required if user is logged in, default creates anonymous ID
        email: userPreferences.email, // Recommended if using Pendo Feedback, or NPS Email
        full_name: userPreferences.name, // Recommended if using Pendo Feedback
        role: userPreferences.role, // Optional
        country_code: userPreferences.defaultCountry?.countryCode,
      },
      // This account needs to be like a group of users and the id of that group, once we have that we can use it
      // account: {
      //   id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      //   name: 'Ayra', // Optional
      //   // is_paying:    // Recommended if using Pendo Feedback
      //   // monthly_value:// Recommended if using Pendo Feedback
      //   // planLevel:    // Optional
      //   // planPrice:    // Optional
      //   // creationDate: // Optional
      //   // You can add any additional account level key-values here,
      //   // as long as it's not one of the above reserved names.
      // },
    })

    if (userPreferences.language) {
      i18n.changeLanguage(currentLanguage || userPreferences.language)
    }

    if (
      userPreferences.shouldDisplaySignupRoutes &&
      !signupRoutes.includes(history.location.pathname) &&
      history.location.pathname !== userPreferences.redirectRoute
    ) {
      history.push(userPreferences.redirectRoute || routes.INITIAL_ROUTE)
    }
  }, [
    history,
    userPreferences.userId,
    userPreferences.email,
    userPreferences.name,
    userPreferences.role,
    userPreferences.language,
    userPreferences.shouldDisplaySignupRoutes,
    userPreferences.redirectRoute,
    i18n,
    currentLanguage,
    userPreferences.defaultCountry?.countryCode,
  ])

  return (
    <React.Suspense fallback={<LoadingCircular />}>
      <TopBar />
      <div className="AppNavContentSplit">
        <div className={classes.sidebarOffset} />
        <Navigation />
        <SettingsSidebar />
        <div className="AppContentLayout">
          <Header />
          <Switch>
            <Route path={signupRoutes} exact={true}>
              <ThemeProvider theme={theme}>
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                  <Route path={routes.onboarding} component={OnboardingPage} />
                  <Route path={routes.pendingAuthorization} component={PendingAuthorizationPage} />
                </Box>
              </ThemeProvider>
            </Route>
            <Route>
              <main className={classes.root} role="main">
                <DiscardChangesProvider>
                  <CustomNavigationPrompt />
                  <Routing />
                  <Snackbar />
                </DiscardChangesProvider>
              </main>
            </Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </React.Suspense>
  )
}
