import { TFunction } from 'i18next'
import { slugTranslationKey } from './slugs'

interface RegionType {
  name: string | null
}

export const getRegionTranslationKey = (name: string) => `regions.${slugTranslationKey(name)}`
export const getRegionName = (region: RegionType | null, t: TFunction): string => {
  if (!region) {
    return ''
  }

  const { name } = region

  if (!name) {
    return ''
  }

  return t(getRegionTranslationKey(name))
}
