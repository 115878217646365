import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from '../routes'
import { UserPreferencesContext } from '../contexts/preferences/UserPreferencesContext'
import ProtectedRoute from '../components/ProtectedRoute'
import { LoadingCircular } from '../components/LoadingCircular'
import { canFromPermissions } from '../userprofile/canFromPermissions'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Zendesk from 'react-zendesk'

/* -------------------------------------------------------------------------------------------------
 * Lazy Imports
 * -----------------------------------------------------------------------------------------------*/

const ClientsRouting = React.lazy(() => import(/* webpackChunkName: "Users" */ './Clients'))
const UsersRouting = React.lazy(() => import(/* webpackChunkName: "Users" */ './Users'))
const AnalysesRouting = React.lazy(
  () => import(/*webpackChunkName: "AnalysesRouting", webpackPrefetch: true */ './Analyses')
)
const CombosRouting = React.lazy(() => import(/*webpackChunkName: "CombosRouting", webpackPrefetch: true */ './Combos'))
const ProgramsRouting = React.lazy(
  () => import(/*webpackChunkName: "ProgramsRouting", webpackPrefetch: true */ './Programs')
)
const BackOfficeRouting = React.lazy(() => import('./BackOffice'))

/* -------------------------------------------------------------------------------------------------
 * Routing
 * -----------------------------------------------------------------------------------------------*/
const Routing: React.FC = () => {
  const { permissions } = React.useContext(UserPreferencesContext)
  const { zendesk, clientsMenu, fvcUpdatesMenu } = useFlags()
  const zendeskKey = window.ENV.REACT_APP_ZENDESK

  const can = canFromPermissions(permissions)
  const canSeeUsers = can('USERS|USER_MANAGEMENT|READ_ALL')

  return (
    <React.Suspense fallback={<LoadingCircular />}>
      {/* @todo Add a proper internal loading */}

      <Switch>
        <Route path={routes.analyses} component={AnalysesRouting} />
        <Route path={routes.combos} component={CombosRouting} />
        <Route path={routes.programs} component={ProgramsRouting} />
        <ProtectedRoute condition={clientsMenu} path={routes.clients} component={ClientsRouting} />
        <ProtectedRoute condition={canSeeUsers} path={routes.users} component={UsersRouting} />
        <ProtectedRoute condition={fvcUpdatesMenu} path={routes.backOffice} component={BackOfficeRouting} />
        <Redirect to={routes.programs} />
      </Switch>
      {zendesk && <Zendesk zendeskKey={zendeskKey} />}
    </React.Suspense>
  )
}

Routing.displayName = 'AppRouting_Internal'

export default Routing
