import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    display: 'inline-block',
    fontWeight: 'normal',
    margin: theme.spacing(2),
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    textTransform: 'none',
  },
}))

const Footer: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <footer className={classes.root}>
      <Grid alignItems="center" container>
        <Grid className={classes.grow} item xs="auto" />
        <Grid item>
          <Link
            className={classes.link}
            color="primary"
            href={t('footer.legal-link') as string}
            target="_blank"
            variant="body2"
          >
            {t('footer.legal')}
          </Link>
          <Link
            className={classes.link}
            color="primary"
            href={t('footer.privacy-link') as string}
            target="_blank"
            variant="body2"
          >
            {t('footer.privacy')}
          </Link>
          <Link
            className={classes.link}
            color="primary"
            href={t('footer.contact-link') as string}
            target="_blank"
            variant="body2"
          >
            {t('footer.contact')}
          </Link>
        </Grid>
      </Grid>
    </footer>
  )
}

export default Footer
