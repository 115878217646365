import _ from 'lodash'
import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

import { AuthContext } from './AuthContext'
import { onNotLogged, onTokenExpired, onUnexpected } from './commons'
import { UserPreferencesProvider } from '../preferences/UserPreferencesProvider'
import { LoadingCircular } from '../../components/LoadingCircular'
import { getClient } from '../../graphql/client'

import { User } from '../../userprofile/types/user'
import { transformAuth0UserToUser, validateAuth0User } from '../../userprofile/auth0'
import { matchAuthenticationErrors } from '../../userprofile/errors/common'

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<Maybe<User>>()
  const { logout, getIdTokenClaims, getAccessTokenSilently, loginWithRedirect } = useAuth0()

  React.useEffect(() => {
    getIdTokenClaims()
      .then(validateAuth0User)
      .then(transformAuth0UserToUser)
      .then(setUser)
      .then(() => getAccessTokenSilently())
      .catch(
        matchAuthenticationErrors({
          noLogged: onNotLogged(loginWithRedirect),
          tokenExpired: onTokenExpired(logout),
          unexpected: onUnexpected(logout),
        })
      )
  }, [getAccessTokenSilently, getIdTokenClaims, loginWithRedirect, logout])

  if (_.isNil(user)) {
    return <LoadingCircular />
  }

  const graphqlClient = getClient(user.token)

  return (
    <AuthContext.Provider value={{ user, logout }}>
      <ApolloProvider client={graphqlClient}>
        <UserPreferencesProvider user={user}>{children}</UserPreferencesProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  )
}

export default AuthProvider
