import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { styled } from '@material-ui/core/styles'
import Helmet from '../containers/Helmet/Helmet'
import { Link as RouterLink } from 'react-router-dom'
import { useQualityAssuranceNamespace } from '../utils/qa/dataTest'

export const PageHeadingGridContainer = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}))

const StyledTypography = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
  }),
  { name: 'StyledTypography' }
)

export interface PageHeadingProps {
  children?: any
  icon?: JSX.Element
  label: string | string[]
  variant?: Variant | 'h2'
  component?: JSX.Element
  labelValues?: Record<string, string | number>
  shouldDisplay?: boolean
}

const PageHeading: React.FC<PageHeadingProps> = ({ label, labelValues, variant = 'h1', shouldDisplay = true }) => {
  const { t } = useTranslation()
  const translatedLabel = t(label, { ...labelValues })
  const IconComponent = () => null

  return (
    <>
      <Helmet title={translatedLabel} />
      {shouldDisplay && (
        <StyledTypography variant={variant}>
          <IconComponent /> {translatedLabel}
        </StyledTypography>
      )}
    </>
  )
}

export type PageHeadingWithActionButtonProps = PageHeadingProps & {
  actionLabel?: string
  actionHref?: string
  actionComponent?: JSX.Element
}

const ActionButton = props => <Button color="secondary" variant="contained" {...props} />

export const PageHeadingWithActionButton: React.FC<PageHeadingWithActionButtonProps> = ({
  actionComponent,
  actionHref = '',
  actionLabel = '',
  ...rest
}) => {
  const { t } = useTranslation()
  const getQualityAssuranceId = useQualityAssuranceNamespace('page-heading')
  const shouldDisplayActionComponent = actionComponent && !actionHref
  const isLocal = actionHref && !actionHref.includes('http')

  return (
    <>
      <PageHeadingGridContainer container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeading {...rest} />
        </Grid>
        <Grid container item xs={9} direction="row" spacing={1} justifyContent="flex-end" alignItems="baseline">
          {shouldDisplayActionComponent ? (
            actionComponent
          ) : isLocal ? (
            <ActionButton
              to={actionHref}
              component={RouterLink}
              data-qa={getQualityAssuranceId('header-action-button')}
            >
              {t(actionLabel)}
            </ActionButton>
          ) : (
            <ActionButton href={actionHref} data-qa={getQualityAssuranceId('header-action-button')}>
              {t(actionLabel)}
            </ActionButton>
          )}
        </Grid>
      </PageHeadingGridContainer>
    </>
  )
}

export default PageHeading
