import * as O from 'fp-ts/Option'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type NotificationSimple = {
  kind: 'simple'
  message: string
}

export type NotificationWithLink = {
  kind: 'link'
  route: string
  message: string
}

export type Notification = NotificationSimple | NotificationWithLink

export const isSimple = (n: Notification): n is NotificationSimple => n.kind === 'simple'
export const isLink = (n: Notification): n is NotificationWithLink => n.kind === 'link'

type Notifications = {
  notification: O.Option<Notification>
}

type MessageReducer = {
  closeNotification: (state: Notifications) => void
  notify: (state: Notifications, action: PayloadAction<string>) => void
  notifyWithLink: (state: Notifications, action: PayloadAction<{ message: string; route: string }>) => void
}

export const notifications = createSlice<Notifications, MessageReducer>({
  name: 'notifications',
  initialState: {
    notification: O.none,
  },
  reducers: {
    closeNotification: state => {
      state.notification = O.none
    },
    notify: (state, action) => {
      state.notification = O.some({
        kind: 'simple',
        message: action.payload,
      })
    },
    notifyWithLink: (state, action) => {
      state.notification = O.some({
        kind: 'link',
        message: action.payload.message,
        route: action.payload.route,
      })
    },
  },
})

export const { notify, notifyWithLink, closeNotification } = notifications.actions
