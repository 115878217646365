import _ from 'lodash'
import { Permission } from './types/preferences'

const impersonationKey = `impersonation`

export type Impersonation = {
  id: string
  name: string
  email: string
  role: string
  permissions: Permission[]
}

const getImpersonation = (): Maybe<Impersonation> => {
  const data = sessionStorage.getItem(impersonationKey)

  if (_.isNil(data)) {
    return undefined
  }

  return JSON.parse(atob(data)) as Impersonation
}

const setImpersonation = (impersonation: Impersonation) => {
  const hash = btoa(JSON.stringify(impersonation))
  sessionStorage.setItem(impersonationKey, hash)
  window.location.reload()
}

const clearImpersonation = () => {
  sessionStorage.removeItem(impersonationKey)
}

export const impersonate = {
  get: getImpersonation,
  set: setImpersonation,
  clear: clearImpersonation,
}
