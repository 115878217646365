import _ from 'lodash'
import React, { useEffect } from 'react'
import { useGetMeQuery } from '../../graphql/gateway/queries/GetMe.generated'
import { REGULAR } from '../../userprofile/roles'
import { impersonate } from '../../userprofile/impersonation'
import { DEFAULTS } from '../../constants/profile'
import { mapPermissions } from '../../utils/permissions'
import routes from '../../routes'
import { LoadingCircular } from '../../components/LoadingCircular'
import LoadingError from '../../components/LoadingError'
import { isManager } from '../../userprofile/IsManager'
import { Permission, UserProfile } from '../../userprofile/types/preferences'
import { UserPreferencesContext } from './UserPreferencesContext'
import { User } from '../../userprofile/types/user'
import { formatName } from '../../utils/formatName'
import { useDispatch } from 'react-redux'
import { ItemsPerPageOptions, setItemsPerPage } from '../../store/preferences/slicer'
import { Monitoring } from '../../utils/monitoring'
import { monitorUserLoggedIn, trackUserLoggedIn } from '../../userprofile/tracking'

type PreferencesProviderProps = {
  readonly user: User
}

export const UserPreferencesProvider: React.FC<PreferencesProviderProps> = ({ user, children }) => {
  const { data: getMeData, loading: getMeLoading, error, refetch } = useGetMeQuery()
  const dispatch = useDispatch()
  const itemsPerPageValueFromUser = localStorage.getItem('itemsPerPage')
  const defaultItemsPerPage = itemsPerPageValueFromUser ? parseInt(itemsPerPageValueFromUser, 10) : 20

  dispatch(setItemsPerPage(defaultItemsPerPage as ItemsPerPageOptions))

  useEffect(() => {
    if (getMeData) {
      trackUserLoggedIn(
        user,
        getMeData.me?.id,
        user.email,
        user.username,
        getMeData.me?.preferences?.country?.name,
        getMeData.me?.preferences?.region?.name
      ).then(() => monitorUserLoggedIn(user))
    }
  }, [getMeData])

  if (getMeLoading) {
    return <LoadingCircular />
  }

  if (error) {
    return <LoadingError errorText={error.message} />
  }

  if (!getMeData || !getMeData.me) {
    return <LoadingCircular />
  }

  const { me } = getMeData
  const { role: getMeRole, preferences } = me

  // @ts-expect-error: Change signature to match both User and User.me result
  const permissions = mapPermissions(me)
  const role = getMeRole.name || REGULAR
  const { email } = me

  const buildProfile = (name: string, role: string, email: string, permissions: Permission[]): UserProfile => {
    const desiredMassUnit = `${preferences?.massUnit || DEFAULTS.MASS_UNIT}/${
      preferences?.areaUnit || DEFAULTS.AREA_UNIT
    }`

    const desiredVolumeUnit = `${preferences?.volumeUnit || DEFAULTS.VOLUME_UNIT}/${
      preferences?.areaUnit || DEFAULTS.AREA_UNIT
    }`

    const authorization = {
      isAdmin: isManager(role),
      isAuthorized: me?.status === 'AUTHORIZED',
      isOnboarded: me?.onboarded,
      isBlocked: me?.status === 'BLOCKED',
    }

    let redirectRoute = routes.INITIAL_ROUTE

    if (!authorization.isOnboarded) {
      redirectRoute = routes.onboarding
    }

    if (authorization.isBlocked || !authorization.isAuthorized) {
      redirectRoute = routes.pendingAuthorization
    }

    if (authorization.isAdmin) {
      const userPermissions: Permission[] = ['USERS|USER_MANAGEMENT|IMPERSONATE']

      const comboPermissions: Permission[] = [
        'COMBOS|USER_MANAGEMENT|WRITE',
        'COMBOS|USER_MANAGEMENT|DELETE',
        'COMBOS|USER_MANAGEMENT|SHARE',
      ]

      permissions.push(...[...userPermissions, ...comboPermissions])
    }

    return {
      name,
      role,
      email,
      redirectRoute,
      permissions,
      defaultCountry: preferences?.country,
      defaultCountryId: preferences?.countryId || DEFAULTS.COUNTRY_ID,
      defaultRegionId: preferences?.regionId || DEFAULTS.REGION_ID,
      defaultRegion: preferences?.region,
      userId: me.id,
      amountUnit: desiredMassUnit,
      amountByDensityUnit: desiredVolumeUnit,
      datePattern: preferences?.datePattern || DEFAULTS.DATE_PATTERN,
      areaUnit: preferences?.areaUnit || DEFAULTS.AREA_UNIT,
      massUnit: preferences?.massUnit || DEFAULTS.MASS_UNIT,
      language: _.get(preferences, 'language', DEFAULTS.LANGUAGE) || DEFAULTS.LANGUAGE,
      lengthUnit: preferences?.lengthUnit || DEFAULTS.LENGTH_UNIT,
      volumeUnit: preferences?.volumeUnit || DEFAULTS.VOLUME_UNIT,
      showContactDetails: preferences?.showContactDetails || false,
      isAuthorized: authorization.isAuthorized,
      isOnboarded: authorization.isOnboarded,
      isBlocked: authorization.isBlocked,
      isAdmin: authorization.isAdmin,
      shouldDisplaySidebarNav: authorization.isAuthorized && authorization.isOnboarded && !authorization.isBlocked,
      shouldDisplaySignupRoutes: authorization.isBlocked || !authorization.isOnboarded || !authorization.isAuthorized,
      isImpersonation: false,
      groupId: user.groupId,
      refetchUserPreferences: refetch,
    }
  }

  let data = buildProfile(formatName(user), role, email, permissions)

  const impersonation = impersonate.get()
  if (impersonation) {
    const { name, role, email, permissions } = impersonation
    data = buildProfile(name, role, email, permissions)
    data.isImpersonation = true
  }

  Monitoring.meta('role', data.role)

  return <UserPreferencesContext.Provider value={data}>{children}</UserPreferencesContext.Provider>
}
