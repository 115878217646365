import * as Types from '../types.d';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {"context":{"gateway":true}}
export type GetCountriesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Array<Types.GenericFilter> | Types.GenericFilter>;
  sorting?: Types.InputMaybe<Array<Types.Sorting> | Types.Sorting>;
  paging?: Types.InputMaybe<Types.Pagination>;
}>;


export type GetCountriesQuery = { __typename?: 'Query', countries: { __typename?: 'ManyCountryResult', entities: Array<{ __typename?: 'Country', id: string, name: string, countryCode: string }> } };


export const GetCountriesDocument = gql`
    query GetCountries($filter: [GenericFilter!], $sorting: [Sorting!], $paging: Pagination) {
  countries(filter: $filter, sorting: $sorting, paging: $paging) {
    entities {
      id
      name
      countryCode
    }
  }
}
    `;

/**
 * __useGetCountriesQuery__
 *
 * To run a query within a React component, call `useGetCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;