import * as Types from '../types.d';

import { gql } from '@apollo/client';
export type UserListFragment = { __typename?: 'User', id: string, givenName: string, familyName: string, email: string, lastLoggedIn?: string | null, source: Types.User_Source, status: Types.User_Status, group?: { __typename?: 'Group', id: string, name: string } | null, role: { __typename?: 'Role', id: string, name: string, roleLevel: number, rolesAndPermissions: Array<{ __typename?: 'RoleAndPermission', permission: { __typename?: 'Permission', action: Types.Action_Names, service: Types.Services, domain?: Types.Domains | null } }> }, preferences?: { __typename?: 'UserPreferences', language?: string | null, country?: { __typename?: 'Country', name: string } | null, region?: { __typename?: 'Region', name: string } | null } | null };

export const UserListFragmentDoc = gql`
    fragment UserList on User {
  id
  givenName
  familyName
  email
  lastLoggedIn
  source
  status
  group {
    id
    name
  }
  role {
    id
    name
    roleLevel
    rolesAndPermissions {
      permission {
        action
        service
        domain
      }
    }
  }
  preferences {
    language
    country {
      name
    }
    region {
      name
    }
  }
}
    `;