import type { User } from '../graphql/gateway/types'
import { UserListFragment } from '../graphql/gateway/queries/UserList.fragment.generated'
import { Permission } from '../userprofile/types/preferences'

/* -------------------------------------------------------------------------------------------------
 * Types
 * -----------------------------------------------------------------------------------------------*/
export type Required<T, K extends keyof T> = T & { [P in K]-?: T[P] }
export type UserType = User & Required<User, 'role'>
/* ---------------------------------------------------------------------------------------------- */

/**
 * Map permissions from request to a form of DOMAIN|SERVICE|ACTION string
 * @param {User & UserListFragment} user
 */
export const mapPermissions = <T extends UserType = User & UserListFragment>(user: T) =>
  (user.role.rolesAndPermissions.map(
    ({ permission }) => `${permission.service}|${permission.domain}|${permission.action}` as Permission
  ) as Permission[]) || []
