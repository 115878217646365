export type UserState = 'logged' | 'noLogged'

export type User = {
  type: UserState
  username: string
  token: string
  email: string
  givenName: string
  familyName: string
  picture: string
  sub: string
  groupId: Maybe<string>
}

export const createUserFromState =
  (state: UserState) =>
  (user: Omit<User, 'type'>): User => ({
    type: state,
    ...user,
  })

export const createLoggedUser = createUserFromState('logged')
export const createNotLoggedUser = createUserFromState('noLogged')
export const notLoggedUser = createNotLoggedUser({
  username: '',
  token: '',
  email: '',
  givenName: '',
  familyName: '',
  picture: '',
  sub: '',
  groupId: null,
})
