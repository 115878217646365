import * as Types from '../types.d';

import { gql } from '@apollo/client';
export type MeFragment = { __typename?: 'User', id: string, status: Types.User_Status, onboarded: boolean, email: string, group?: { __typename?: 'Group', id: string, name: string } | null, preferences?: { __typename?: 'UserPreferences', areaUnit?: string | null, countryId?: string | null, datePattern?: string | null, lengthUnit?: string | null, massUnit?: string | null, language?: string | null, phone?: string | null, profile?: string | null, regionId?: string | null, showContactDetails?: boolean | null, subregion?: string | null, userId?: string | null, volumeUnit?: string | null, country?: { __typename?: 'Country', id: string, name: string, countryCode: string } | null, region?: { __typename?: 'Region', id: string, name: string } | null } | null, role: { __typename?: 'Role', id: string, name: string, rolesAndPermissions: Array<{ __typename?: 'RoleAndPermission', permission: { __typename?: 'Permission', action: Types.Action_Names, domain?: Types.Domains | null, service: Types.Services } }> } };

export const MeFragmentDoc = gql`
    fragment MeFragment on User {
  id
  status
  onboarded
  email
  group {
    id
    name
  }
  preferences {
    areaUnit
    country {
      id
      name
      countryCode
    }
    countryId
    datePattern
    lengthUnit
    massUnit
    language
    phone
    profile
    region {
      id
      name
    }
    regionId
    showContactDetails
    subregion
    userId
    volumeUnit
  }
  role {
    id
    name
    rolesAndPermissions {
      permission {
        action
        domain
        service
      }
    }
  }
}
    `;