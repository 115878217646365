import React from 'react'
import { DEFAULTS } from '../../constants/profile'
import { REGULAR } from '../../userprofile/roles'
import { UserProfile } from '../../userprofile/types/preferences'

const userPreferencesContext = React.createContext<UserProfile>({
  name: '',
  userId: '',
  amountUnit: '',
  email: '',
  amountByDensityUnit: '',
  role: REGULAR,
  isAdmin: false,
  isAuthorized: false,
  isBlocked: false,
  isOnboarded: false,
  permissions: [],
  defaultCountry: undefined,
  defaultRegion: undefined,
  datePattern: DEFAULTS.DATE_PATTERN,
  areaUnit: DEFAULTS.AREA_UNIT,
  massUnit: DEFAULTS.MASS_UNIT,
  language: DEFAULTS.LANGUAGE,
  lengthUnit: DEFAULTS.LENGTH_UNIT,
  volumeUnit: DEFAULTS.VOLUME_UNIT,
  showContactDetails: false,
  shouldDisplaySidebarNav: false,
  isImpersonation: false,
  groupId: null,
  refetchUserPreferences: undefined,
})

userPreferencesContext.displayName = 'UserPreferencesContext'

export { userPreferencesContext as UserPreferencesContext }
