import type { UserProfile } from './types/preferences'
import type { LDMultiKindContext } from 'launchdarkly-js-client-sdk'

export const buildLaunchDarklyContext = (profile: UserProfile): LDMultiKindContext => ({
  kind: 'multi',
  user: {
    kind: 'user',
    key: profile.email,
    name: profile.name,
    email: profile.email,
    role: profile.role,
  },
  group: {
    kind: 'group',
    key: profile.groupId || 'none',
  },
})
