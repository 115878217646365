import { User } from './types/user'
import { analyticsForBoth } from '../utils/analytics'
import { Monitoring } from '../utils/monitoring'

export const trackUserLoggedIn = async (
  user: User,
  userId = '',
  userEmail = '',
  userName = '',
  country?: string,
  region?: string
): Promise<User> => {
  await analyticsForBoth('identify', userId, {
    email: userEmail,
    name: userName,
    country,
    region,
    userId,
  })
  await analyticsForBoth('track', 'User - Logged In', {
    country,
    userId,
    email: userEmail,
  })

  return user
}

export const monitorUserLoggedIn = (user: User): User => {
  Monitoring.meta('version', window.ENV.REACT_APP_VERSION)
  Monitoring.user(user)

  return user
}
