import React from 'react'
import { Button, ButtonGroup, makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  button: {
    fontWeight: 500,
    color: theme.palette.text.secondary,
    borderColor: theme.palette.text.secondary,
  },
  active: {
    background: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      background: theme.palette.secondary.main,
    },
  },
}))

interface Option {
  title: string
  value: string
  qa: string
}

interface SwitchSelectProps {
  options: Option[]
  value: string
  onChange: (value: string) => void
}

const SwitchSelect = (props: SwitchSelectProps): JSX.Element => {
  const { options, value, onChange } = props

  const classes = useStyles()

  return (
    <ButtonGroup fullWidth>
      {options.map((option, index) => (
        <Button
          key={index}
          onClick={() => onChange(option.value)}
          className={cn(classes.button, { [classes.active]: value === option.value })}
        >
          {option.title}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default SwitchSelect
