import React from 'react'
import { Link, makeStyles, Typography } from '@material-ui/core'
import { impersonate } from '../userprofile/impersonation'
import { useTranslation } from 'react-i18next'
import { useQualityAssuranceNamespace } from '../utils/qa/dataTest'
import { UserPreferencesContext } from '../contexts/preferences/UserPreferencesContext'

const useStyles = makeStyles(theme => ({
  topBar: {
    backgroundColor: 'black',
    color: 'white',
    paddingTop: theme.spacing(1),
    display: 'block',
    position: 'fixed',
    width: '100%',
    height: theme.spacing(5),
    zIndex: 99,
  },
  topBarLink: {
    color: '#FFF2C5',
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
}))

const TopBar: React.FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const getQualityAssuranceId = useQualityAssuranceNamespace('impersonation')
  const preferences = React.useContext(UserPreferencesContext)

  const onCancel = React.useCallback(() => {
    impersonate.clear()
    window.location.reload()
  }, [])

  return preferences.isImpersonation ? (
    <Typography
      noWrap={true}
      className={classes.topBar}
      variant="body2"
      display="block"
      align="center"
      data-qa={getQualityAssuranceId('bar')}
    >
      {t('users.impersonate.disclaimer')} <strong>{preferences.name}</strong>.
      <Link className={classes.topBarLink} onClick={onCancel} data-qa={getQualityAssuranceId('disable')}>
        {t('users.impersonate.disable')}
      </Link>
    </Typography>
  ) : null
}

export default TopBar
